import React from "react";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import Testimonials from "../components/Repeating/Testimonials";
import About from "../components/Repeating/About";
import CallToAction from "../components/Repeating/CTA";
import PersonalServiceSidebar from "../components/Repeating/PersonalServiceSidebar";
import PersonalServiceSlider from "../components/Repeating/PersonalServiceSlider";
import ButtonSolid from "../components/Button/ButtonSolid";

const Page = ({ data }) => {
  return (
    <Layout>
      <SearchEngineOptimization
        title="Personal Tax Preparation & Planning Services | DC, MD & VA"
        description="We help you plan, take advantage of money-saving tax breaks, and prepare your returns. Call DeBlanc + Murphy today. Serving the greater Washington, DC, area."
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />
      <section className="pt-20 pb-10 md:py-14">
        <div className="container">
          <div className="max-w-[800px]">
            <h1>Personal Tax Prep & Planning Services</h1>
            <p className="mb-0 md:text-xl">
              We help you plan ahead, take advantage of money-saving tax breaks,
              and prepare your returns. Serving the greater Washington, DC area.
            </p>
          </div>
        </div>
      </section>

      <section className="relative mb-4 h-[375px] md:mb-32 md:h-[504px]">
        <BgImage
          image={getImage(data.parallaxImage.childImageSharp.gatsbyImageData)}
          className="h-full w-full bg-fixed"
        ></BgImage>
        <div className="gradient-blend absolute bottom-0 h-28 w-full"></div>
      </section>

      <section className="relative mb-20 md:mb-32">
        <div className="container">
          <div className="grid gap-y-20 md:grid-cols-12">
            <div className="md:col-span-8 md:col-start-1">
              <div className="mb-16">
                <h2>For Individuals and Families</h2>
                <p>
                  It’s easy to feel overwhelmed by taxes, or unsure where to
                  even begin to prepare your returns. Leave the tax preparation
                  to us and ease your stress. And when you’re ready to plan for
                  your future, we’ll help you with your personal tax planning.
                </p>
              </div>

              <div className="mb-16">
              <h2>Who We Serve</h2>
                <ul className="styled-list-plus alt mb-6">
                  <li>Business owners</li>
                  <li>Investors</li>
                  <li>Entrepreneurs</li>
                  <li>Real estate professionals</li>
                  <li>C-suite executives</li>
                  <li>High-net-worth individuals and their families</li>

                </ul>

                <h2>Our Areas of Tax Expertise</h2>
                <p>
                We have a deep experience in all facets of individual taxation, including:
                </p>
                <ul className="styled-list-plus alt mb-6">
                  <li>Private and public investments</li>
                  <li>Real estate professionals</li>
                  <li>Equity compensation and stock option</li>
                  <li>Tax-efficient investing and divestitures</li>
                  <li>International taxation</li>
                  <li>State and local taxation</li>
                  <li>Cryptoasset taxation</li>
                </ul>


                <h2>Filing Personal Taxes</h2>
                <p>
                  At DeBlanc, Murphy & Murphy, we do more than fill out your tax
                  forms—we give you personal tax advice. We’ll look for ways to reduce taxable income, seek deductions, and help you take advantage of tax credits. As your tax preparer, our goal is to file an accurate return while ensuring you pay the least tax required by law.
                </p>
              </div>

              <div>
                <h2>Personal Tax Planning</h2>
                <p>
                  Tax planning isn’t only beneficial for businesses. There are
                  many advantages for individuals as well. Thoughtful tax
                  planning can:
                </p>
                <ul className="styled-list-plus alt mb-6">
                  
                  <li>
                  Lower your income taxes so you can keep more of your income today and pay less taxes in the future
                  </li>
                  <li>
                    Lower taxes on your estate and gifts, so your beneficiaries
                    keep more
                  </li>
                  <li>
                    Lower taxes on investments and retirement distributions, so
                    you maintain your lifestyle
                  </li>
                </ul>

                <p>
                  Our tax planners also stay on top of new tax laws and
                  legislation throughout the year to further help you save
                  money.
                </p>

                <ButtonSolid modal="modal-contact" altStyle={2} text="Get a Quote" />
              </div>
            </div>
            <div className="hidden md:col-span-4 md:col-end-13 md:block">
              <PersonalServiceSidebar activeService={0} />
            </div>
          </div>
        </div>
      </section>

      <Testimonials />
      <PersonalServiceSlider hideService={0} />
      <About paddingTop={true} />
      <CallToAction />
    </Layout>
  );
};

export const data = graphql`
  {
    parallaxImage: file(
      relativePath: { eq: "3.1 Personal Tax Prep _ Planning/1.0 Hero.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;

export default Page;
